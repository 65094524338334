import { HumpDay } from "./components/HumpDay";
import { GlobalStyles } from "./styles/global";
import { isHumpDay as _isHumpDay } from "./util/day";
import { CurrentDay } from "./components/CurrentDay";

function App() {
    const isHumpDay = _isHumpDay();

    return (
        <>
            <GlobalStyles />
            <div className="App">
                <header className="App-header">
                    {isHumpDay && <HumpDay />}
                    {!isHumpDay && <CurrentDay />}
                </header>
            </div>
        </>
    );
}

export default App;
