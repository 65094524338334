import styled from "styled-components";
import { getCurrentDayName } from "../util/day";
import { db } from "../lib/firebase";
import { doc, increment, getDoc, setDoc } from "firebase/firestore";
import { useEffect, useState, MouseEvent } from "react";
import { mike } from "../emitters";

const Container = styled.p`
    font-size: 64px;
    cursor: pointer;
    &:hover {
        transition: all 0.2s ease-in-out;
        transform: scale(1.1);
    }
    &:active {
        transition: all 0.2s ease-in-out;
        transform: scale(0.9);
    }
`;

export const CurrentDay = () => {
    const currentDayName = getCurrentDayName();
    const [currentCount, setCurrentCount] = useState(0);

    const refreshCurrentCount = async () => {
        const document = doc(db, "app", "mikes");
        const docSnap = await getDoc(document);
        if (docSnap.exists()) {
            setCurrentCount(docSnap.data().total);
        }
    };

    useEffect(() => {
        void refreshCurrentCount();
    }, []);

    useEffect(() => {
        const listener = document
            .querySelector("#current-day")
            ?.addEventListener("click", function (e) {
                // @ts-ignore
                mike(this);
                // party.confetti(this, {
                //     // Specify further (optional) configuration here.
                //     count: party.variation.range(20, 40),
                // });
            });

        return () => {
            listener
                ? document.removeEventListener("click", listener)
                : undefined;
        };
    }, []);

    const handleDayClick = async (e: MouseEvent<HTMLParagraphElement>) => {
        await setDoc(doc(db, "app", "mikes"), { total: increment(1) });
    };

    return (
        <Container id="current-day" onClick={handleDayClick}>
            {currentDayName}
        </Container>
    );
};
