// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCtd-k5pawEpbPWbJNtj6z4seo9FxcCq7M",
    authDomain: "mikemikemike-b9c3f.firebaseapp.com",
    projectId: "mikemikemike-b9c3f",
    storageBucket: "mikemikemike-b9c3f.appspot.com",
    messagingSenderId: "506358296650",
    appId: "1:506358296650:web:7e2916fad0a51a83c176b3",
    measurementId: "G-1XXY6EGXZG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const analytics = getAnalytics(app);
