import React, { useEffect } from "react";
import styled from "styled-components";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";


const Wrapper = styled.div`
    flex-wrap: wrap;
    overflow-wrap: anywhere;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const Background = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    max-height: 100vh;
    overflow-y: clip;
`;

const Container = styled.div`
    position: absolute;
    flex: 1;
    display: flex;
    z-index: 1;
`;

const Text = styled.p`
    font-size: 64px;
`;


const HumpDayText = styled.p`
    font-size: 64px;
    animation: humpday 1.5s infinite ease-in-out;
    @keyframes humpday {
        0% {
            transform: rotate(0deg) scale(1);
        }
        25% {
            transform: rotate(360deg) scale(2);
        }
        35% {
            transform: rotate(360deg) scale(3);
        }
        45% {
            transform: rotate(360deg) scale(2);
        }
        50% {
            transform: rotate(360deg) scale(3);
        }
        60% {
            transform: rotate(360deg) scale(2);
        }
        75% {
            transform: rotate(360deg) scale(2);
        }
        100% {
            transform: rotate(0deg) scale(1);
        }
    }
`;
const CAMEL = "🐪";

export const HumpDay = () => {
  const { width, height } = useWindowSize();
    const [numCamels, setNumCamels] = React.useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            // Every half second add letters to build out the phrase HUMP DAYYYYYYYYYY
            // TODO: Make this smarter
              setNumCamels((prev) => prev >= 500 ? 0 : prev + 1);
        }, 25);

        return () => clearInterval(interval);
    }, []);

    const camels = CAMEL.repeat(numCamels);

    return (
        <Wrapper>
            <Confetti width={width} height={height} gravity={0.5} />
            <Background>
                <Text>{camels}</Text>
            </Background>
            <Container style={{ fontSize: "5em" }}>
                <HumpDayText>HUMP DAY</HumpDayText>
            </Container>
        </Wrapper>
    );
};
