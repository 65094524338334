

export const getCurrentDayName = () => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const date = new Date();
  const day = date.getDay();
  return days[day];
}

export const isHumpDay = () => {
  const day = getCurrentDayName();
  return day === 'Wednesday';
}
