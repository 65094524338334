import party from "party-js";

export const mike = (target: party.sources.DynamicSourceType) => {
    const mikeShape = document.createElement("p");
    mikeShape.innerHTML = "Mike";
    mikeShape.style.color = "white";
    mikeShape.style.fontSize = "32px";
    mikeShape.style.fontWeight = "bold";

    return party.scene.current.createEmitter({
        emissionOptions: {
            rate: 0,
            bursts: [{ time: 0, count: party.variation.skew(20, 10) }],
            sourceSampler: party.sources.dynamicSource(target),
            angle: party.variation.range(180, 360),
            initialSpeed: 800,
            initialColor: party.variation.gradientSample(
                party.Gradient.simple(
                    party.Color.fromHex("#C792EA"),
                    party.Color.fromHex("#89DDFF"),
                    party.Color.fromHex("#F58B6C")
                )
            ),
        },
        rendererOptions: {
            shapeFactory: mikeShape,
            applyLighting: undefined,
        },
    });
};
